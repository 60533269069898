import React from "react"
import { Link } from "gatsby"
import Layout from "../templates/pageLayout"
import * as legalStyles from "./legalStyles.module.css"

export default function CookiesPolicy() {
  return (
    <Layout title="Cookies Policy">
      <div className="container">
        <div>
          <h1>Cookies Policy</h1>
          <div className={legalStyles.mainContainer}>
            <div>
              <h4>WHAT ARE COOKIES</h4>
              <p>
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or ‘break’ certain elements of the sites
                functionality. For more general information on cookies see the
                Wikipedia article on HTTP Cookies.
              </p>
            </div>
            <div>
              <h4>HOW WE USE COOKIES</h4>
              <p>
                We use some unobtrusive cookies to store information on your
                computer.Some cookies on this site are essential, and the site
                won’t work as expected without them. These cookies are set when
                you submit a form or interact with the site by doing something
                that goes beyond clicking on simple links.We also use some
                non-essential cookies to anonymously track visitors or enhance
                your experience of the site.
              </p>
            </div>
            <div>
              <h4>DISABLING COOKIES</h4>
              <p>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of the this site. Therefore it is recommended that you
                do not disable cookies.
              </p>
            </div>
            <div>
              <h4>COOKIES WE SET</h4>
              <p>
                {" "}
                This site offers newsletter or email subscription services and
                cookies may be used to remember if you are already registered
                and whether to show certain notifications which might only be
                valid to subscribed/unsubscribed users.
                <br />
                <br />
                From time to time we offer user surveys and questionnaires to
                provide you with interesting insights, helpful tools, or to
                understand our user base more accurately. These surveys may use
                cookies to remember who has already taken part in a survey or to
                provide you with accurate results after you change pages.
                <br />
                <br />
                When you submit data to through a form such as those found on
                contact pages or comment forms cookies may be set to remember
                your user details for future correspondence.
              </p>
            </div>
            <div>
              <h4>THIRD PARTY COOKIES</h4>
              <p>
                {" "}
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
                <br />
                <br />
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solution on the web for helping
                us to understand how you use the site and ways that we can
                improve your experience. These cookies may track things such as
                how long you spend on the site and the pages that you visit so
                we can continue to produce engaging content. <br />
                <br />
                For more information on Google Analytics cookies, see the
                official Google Analytics page.
                <br />
                <br />
                Third party analytics are used to track and measure usage of
                this site so that we can continue to produce engaging content.
                These cookies may track things such as how long you spend on the
                site or pages you visit which helps us to understand how we can
                improve the site for you.
                <br />
                <br />
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
                <br />
                <br />
                As we sell products it’s important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
                <br />
                <br />
                The Google AdSense service we use to serve advertising uses a
                DoubleClick cookie to serve more relevant ads across the web and
                limit the number of times that a given ad is shown to you.
                <br />
                <br />
                For more information on Google AdSense see the official Google
                AdSense privacy FAQ.
                <br />
                <br />
                Several partners advertise on our behalf and affiliate tracking
                cookies simply allow us to see if our customers have come to the
                site through one of our partner sites so that we can credit them
                appropriately and where applicable allow our affiliate partners
                to provide any bonus that they may provide you for making a
                purchase.
                <br />
                <br />
                We also use social media buttons and/or plugins on this site
                that allow you to connect with your social network in various
                ways. For these to work the following social media sites
                including; Facebook, Twitter, YouTube, will set cookies through
                our site which may be used to enhance your profile on their site
                or contribute to the data they hold for various purposes
                outlined in their respective privacy policies.
                <br />
                <br />
              </p>
            </div>
            <div>
              <h4>MORE INFORMATION</h4>
              <p>
                Hopefully that has clarified things for you and as was
                previously mentioned if there is something that you aren’t sure
                whether you need or not it’s usually safer to leave cookies
                enabled in case it does interact with one of the features you
                use on our site. However if you are still looking for more
                information then you can contact us through one of our preferred
                contact methods listed on{" "}
                <Link to="/contact">"Contact Us"</Link> page.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
